<template>
  <AppPage
    :title="$vuetify.lang.t('$vuetify.vet_detail')"
    container
    :loading="loading"
  >
    <div class="vet-container" v-if="currentPost">
      <v-row>
        <v-col cols="4">
          <SingleImage :src="currentPost.media[0]" max-height="100px" />
        </v-col>
        <v-col cols="8">
          <div class="vet-info">
            <h3>{{ currentPost.name }} ({{ currentPost.country }})</h3>
          </div>
        </v-col>
      </v-row>

      <p class="title">{{ $vuetify.lang.t("$vuetify.address") }}</p>
      <p class="sm value">{{ currentPost.address }}</p>

      <p class="title">Adoption : {{ !!currentPost.adoption ? 'Yes' : "No" }} </p>
      <p class="title">Vacinated : {{ !!currentPost.vaccine ? 'Yes' : "No" }} </p>
      <p class="title">Race : {{ currentPost.race.name }} </p>
      <SingleImage :src="currentPost.race.image" max-height="100px" ></SingleImage>

    </div>
  </AppPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import singleImage from '../../components/media/single-image.vue';
const feedModule = createNamespacedHelpers("sellerPets");
export default {
  components: { singleImage },
    name: "SellerPet",
    data() {
        return{
            petID: null
        };
    },
    async mounted() {
        this.petID = this.$route.params.id;
        console.log(this.petID);
        await this.FETCH_SINGLE_POST(this.petID);
        console.log('currentPost', this.currentPost);
    },
    computed: {
        ...feedModule.mapState(["currentPost", "loading"])
    },
    methods: {
        ...feedModule.mapActions(["FETCH_SINGLE_POST"]),
    }
}
</script>

<style>

</style>